/*---------------------------------
  Section or Content Box
----------------------------------*/

section.box {
    background-color: #ffffff;
    margin: 0px 0 30px 0;
    box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -webkit-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -moz-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -o-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -ms-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);

}

section header {
    width: 100%;
    display: inline-block;
    /* background-color: #ffffff; */
    border: 0px solid rgba(0, 0, 0, 0.09);
    border-bottom: 0px solid transparent;
    vertical-align: top;
    position: relative;
    min-height: 80px;
}

section header .title {
    margin-top: 10px;
    font-family: $menu-family;
    font-size: 19px;
    padding-left: 30px;
    padding-top: 15px;
    line-height: 30px;
    color: #676767;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
}

section header .close {
    padding-right: 30px;
    padding-top: 1rem;
}

section .content-body {
    padding: 0px 30px 30px 30px;
    /* background-color: #ffffff; */
    border: 0px solid #e8e8e8;
    border-top: 0px;
    -webkit-transition: 800ms;
    -moz-transition: 800ms;
    -o-transition: 800ms;
    transition: 800ms;
}

section.box.nobox {
    background-color: transparent;
    /*box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    -ms-box-shadow: none; */
}

section.nobox header {
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 0px solid transparent;
}

section.nobox header .title {
    padding-left: 0px;
}

section.nobox .content-body {
    background-color: transparent;
    border: 0px solid transparent;
    padding: 0 0px 0px 0px;
}

section.box .actions {
    margin: 10px 15px 0 15px;
    line-height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
}

section.box ul .actions {
    margin-left: 0px;
}

section.box .actions a {
    color: #bcbcbc;
    font-size: 12px;
    margin-left: 0px;
    padding: 5px;
    cursor: hand;
    cursor: pointer;
    text-decoration: none;
}

section.box .actions a:hover {
    color: #999999;
    text-decoration: none;
}

section .content-body.collapsed {
    /*display: none;*/
    /*  height: 0px !important;
  overflow: hidden;
  max-height: 0px !important;
  padding: 0px 30px !important;*/
}

.db_box {
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -webkit-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -moz-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -o-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);
    -ms-box-shadow: 0 1px 3px rgba(33, 33, 33, .2);

}

.db_box .chart-area {
    margin-left: 0px;
}

.chart-container {
    padding-top: 0px;
    margin-left: -7px;
}

/*--------ui panels section for different colors--------*/
/*transparent*/

section.box.transparent {
    background-color: transparent;
}

section.transparent header {
    background-color: transparent;
    border: 1px solid #e8e8e8;
    border-bottom: 2px solid $primary-color;
}

section.transparent .content-body {
    background-color: transparent;
    border: 1px solid #e1e1e1;
    border-top: 0px;
}

/*inverted*/

section.box.inverted {
    background-color: #777777;
    color: #dddddd;
}

section.inverted header {
    background-color: rgba(50, 50, 58, 0.8);
    border: 1px solid #333333;
    border-bottom: 2px solid $primary-color;
    color: #cccccc;
}

section.inverted header .title {
    color: #dddddd;
}

section.inverted .content-body {
    background-color: rgba(50, 50, 58, 0.4);
    border: 1px solid #333333;
    color: #dddddd;
    border-top: 0px;
}

/*----all colors----*/

section.primary header {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-bottom: 2px solid $primary-color;
    color: #f5f5f5;
}

section.purple header {
    background-color: $purple-color;
    border: 1px solid $purple-color;
    border-bottom: 2px solid $purple-color;
    color: #f5f5f5;
}

section.accent header {
    background-color: $accent-color;
    border: 1px solid $accent-color;
    border-bottom: 2px solid $accent-color;
    color: #f5f5f5;
}

section.success header {
    background-color: $success-color;
    border: 1px solid $success-color;
    border-bottom: 2px solid $success-color;
    color: #f5f5f5;
}

section.warning header {
    background-color: $warning-color;
    border: 1px solid $warning-color;
    border-bottom: 2px solid $warning-color;
    color: #f5f5f5;
}

section.danger header {
    background-color: $danger-color;
    border: 1px solid $danger-color;
    border-bottom: 2px solid $danger-color;
    color: #f5f5f5;
}

section.info header {
    background-color: $info-color;
    border: 1px solid $info-color;
    border-bottom: 2px solid $info-color;
    color: #f5f5f5;
}

section.primary header .title,
section.purple header .title,
section.accent header .title,
section.success header .title,
section.warning header .title,
section.danger header .title,
section.info header .title {
    color: #f5f5f5;
}

section.box.primary .actions i,
section.box.purple .actions i,
section.box.accent .actions i,
section.box.success .actions i,
section.box.warning .actions i,
section.box.info .actions i,
section.box.danger .actions i {
    color: #e1e1e1;
}

section.box.primary .actions i:hover,
section.box.purple .actions i:hover,
section.box.accent .actions i:hover,
section.box.success .actions i:hover,
section.box.warning .actions i:hover,
section.box.info .actions i:hover,
section.box.danger .actions i:hover {
    color: #f5f5f5;
}