
/*-----------------------------------------------------------
    Blogs
----------------------------------------------------------*/

.blog_post {
    display: inline-block;
    width: 100%;
    margin: 0 0 10px 0;
}
.full_blog_post {
    margin: 0 0 15px 0;
}
.blog_post h3 {
    margin: 0 0 15px 0;
}
.blog_post h5 {
    margin: 0 0 15px 0;
}
.blog_info {
    margin: 0 0 15px 0;
}
.blog_info a, .blog_info i {
    margin-right: 5px;
}
.blog-content {
    margin: 0px 0 30px 0;
    display: inline-block;
    width: 100%;
    max-width: 960px;
}
.media-object {
    max-height: none;
    margin: 0px 0 15px 0;
    max-width: 800px;
    width: 100%;
    height: auto;
}
.comment-block.row{
    margin-left: 0px;
    margin-right: 0px;
}
.full_blog_post .comment-block{
    padding: 15px;
    margin-bottom: 15px;
}
.full_blog_post .comment-block img {
    width: 100%;
    height: auto;
    max-width: 66px;
}
.full_blog_post .comment-block .img-area {
    padding: 0px;
    margin-left: -35px;
}
.full_blog_post .comment-block.level-2 {
    margin-left: 45px;
    width: calc(100% - 45px);
}
.full_blog_post .comment-block.level-3 {
    margin-left: 90px;
    width: calc(100% - 90px);
}
.search_data{
    margin-top: 30px;
}
#comments{
    max-width: 960px;
    width: calc(100% - 30px);

}
.comment-block{
    margin-top: 15px;
    left: 25px;    
}
.comment-block .text-area{
    padding-left: 10px;
}
.comment-block .author{
    margin-top: 0px;
}
.comment-form{
    margin-top: 25px;
}
.comment-form{
    max-width: 1020px;
    width: 100%;
}

.blog_post{
    .post-by{
        margin-bottom: 10px;
    }
    .blogtitle{
        margin-bottom: 5px;
    }
}
.full_blog_post .media-object{
    margin: 15px 0 30px 0;
}
h3.blogtitle{
    margin-top: 5px;
}
h3.blogtitle a{
    margin-top: 5px;
    font-size: 22px;
}