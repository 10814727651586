
/*---------------------------------------------
    Progress Bars
----------------------------------------------*/

.progress {
    background: #f5f5f5;
    background-image: none;
    -webkit-box-shadow: inset 0 -1px 2px rgba(150, 150, 150, .1);
    -moz-box-shadow: inset 0 -1px 2px rgba(150, 150, 150, .1);
    box-shadow: inset 0 -1px 2px rgba(150, 150, 150, .1);
    border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}
.progress.progress-md {
    height: 30px;
}
.progress.progress-lg {
    height: 40px;
}
.progress.progress-xlg {
    height: 50px;
}
.progress-bar {
    background-color: $primary-color;
    background-image: none;
    font-size: 12px;
    line-height: 20px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, .15);
    -moz-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, .15);
    box-shadow: inset 0 -1px 0 rgba(100, 100, 100, .15);
}
.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}
.progress-md .progress-bar {
    font-size: 14px;
    line-height: 30px;
}
.progress-lg .progress-bar {
    font-size: 16px;
    line-height: 40px;
}
.progress-xlg .progress-bar {
    font-size: 18px;
    line-height: 50px;
}
.progress-bar-purple {
    background-color: $purple-color;
}
.progress-bar-primary {
    background-color: $primary-color;
}
.progress-bar-accent {
    background-color: $accent-color;
}
.progress-bar-secondary {
    background-color: #9e9e9e;
}
.progress-bar-red {
    background-color: $red-color;
}

.tile-progress .progress .progress-bar.bg-primary{
    background-color: $primary-color;
}
.tile-progress .progress .progress-bar.bg-purple{
    background-color: $purple-color;
}
.tile-progress .progress .progress-bar.bg-accent{
    background-color: $accent-color;
}
.tile-progress .progress .progress-bar.bg-warning{
    background-color: $warning-color;
}
.tile-progress .progress .progress-bar.bg-success{
    background-color: $success-color;
}
.tile-progress .progress .progress-bar.bg-info{
    background-color: $info-color;
}
.tile-progress .progress .progress-bar.bg-danger{
    background-color: $danger-color;
}
.tile-progress .progress .progress-bar.bg-secondary{
    background-color: $secondary-color;
}

