
/*---------------------------------------------
      Timeline Centered Style
----------------------------------------------*/

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}
.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 10px;
    background-color: #eaeaea;
    left: 50%;
    margin-left: -5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.timeline > li {
    margin-bottom: 20px;
    position: relative;
}
.timeline > li:before, .timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li:before, .timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li > .timeline-panel {
    width: 45%;
    float: left;
    border: 1px solid #eaeaea;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 25px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(150, 150, 150, 0.175);
    -moz-box-shadow: 0 1px 6px rgba(150, 150, 150, 0.175);
    box-shadow: 0 1px 6px rgba(150, 150, 150, 0.175);
    z-index: 11;
    margin-left: -15px;
}
.timeline > li > .timeline-panel:before {
    position: absolute;
    top: 36px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #eaeaea;
    border-right: 0 solid #eaeaea;
    border-bottom: 15px solid transparent;
    content: " ";
}
.timeline > li > .timeline-panel:after {
    position: absolute;
    top: 37px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}
.timeline > li > .timeline-badge {
    color: #fff;
    width: 70px;
    height: 70px;
    line-height: 58px;
    font-size: 28px;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -35px;
    background-color: $purple-color;
    z-index: 10;
    -webkit-border-top-right-radius: 50%;
    -moz-border-radius-topright: 50%;
    border-top-right-radius: 50%;
    -webkit-border-top-left-radius: 50%;
    -moz-border-radius-topleft: 50%;
    border-top-left-radius: 50%;
    -webkit-border-bottom-right-radius: 50%;
    -moz-border-radius-bottomright: 50%;
    border-bottom-right-radius: 50%;
    -webkit-border-bottom-left-radius: 50%;
    -moz-border-radius-bottomleft: 50%;
    border-bottom-left-radius: 50%;
    border: 5px solid #eaeaea;
}
.timeline > li > .timeline-badge i {
    font-size: 28px;
}
.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    margin-right: -15px;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}
.timeline-badge.primary {
    background-color: $primary-color !important;
}
.timeline-badge.success {
    background-color: $success-color !important;
}
.timeline-badge.warning {
    background-color: $warning-color !important;
}
.timeline-badge.danger {
    background-color: $danger-color !important;
}
.timeline-badge.info {
    background-color: $info-color !important;
}
.timeline-title {
    margin-top: 0;
    color: inherit;
}
.timeline-body > p, .timeline-body > ul {
    margin-bottom: 0;
}
.timeline-body > p + p {
    margin-top: 5px;
}
@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }
    ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }
    ul.timeline > li > .timeline-panel {
        float: right;
        margin-right: -15px;
    }
    ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }
    ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}
.timeline-title{
    margin-bottom: 5px;
}
/*---------------------------------------------
      Timeline Left Aligned Style
----------------------------------------------*/

.timeline2-centered {
    position: relative;
    margin-bottom: 30px;
}
.timeline2-centered:before, .timeline2-centered:after {
    content: " ";
    display: table;
}
.timeline2-centered:after {
    clear: both;
}
.timeline2-centered:before, .timeline2-centered:after {
    content: " ";
    display: table;
}
.timeline2-centered:after {
    clear: both;
}
.timeline2-centered:before {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    background: #f5f5f6;
    top: 20px;
    bottom: 20px;
    margin-left: 26px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.timeline2-centered .timeline2-entry {
    position: relative;
    margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 10px;
    clear: both;
}
.timeline2-centered .timeline2-entry:before, .timeline2-centered .timeline2-entry:after {
    content: " ";
    display: table;
}
.timeline2-centered .timeline2-entry:after {
    clear: both;
}
.timeline2-centered .timeline2-entry:before, .timeline2-centered .timeline2-entry:after {
    content: " ";
    display: table;
}
.timeline2-centered .timeline2-entry:after {
    clear: both;
}
.timeline2-centered .timeline2-entry.begin {
    margin-bottom: 0;
}
.timeline2-centered .timeline2-entry.left-aligned {
    float: left;
}
.timeline2-centered .timeline2-entry.left-aligned .timeline2-entry-inner {
    margin-left: 0;
    margin-right: -18px;
}
.timeline2-centered .timeline2-entry.left-aligned .timeline2-entry-inner .timeline2-time {
    left: auto;
    right: -100px;
    text-align: left;
}
.timeline2-centered .timeline2-entry.left-aligned .timeline2-entry-inner .timeline2-icon {
    float: right;
}
.timeline2-centered .timeline2-entry.left-aligned .timeline2-entry-inner .timeline2-label {
    margin-left: 0;
    margin-right: 70px;
}
.timeline2-centered .timeline2-entry.left-aligned .timeline2-entry-inner .timeline2-label:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -9px;
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner {
    position: relative;
    margin-left: -20px;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner:before, .timeline2-centered .timeline2-entry .timeline2-entry-inner:after {
    content: " ";
    display: table;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner:after {
    clear: both;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner:before, .timeline2-centered .timeline2-entry .timeline2-entry-inner:after {
    content: " ";
    display: table;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner:after {
    clear: both;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-time {
    position: absolute;
    left: -100px;
    text-align: right;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-time > span {
    display: block;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-time > span:first-child {
    font-size: 15px;
    font-weight: bold;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-time > span:last-child {
    font-size: 12px;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon {
    background: #fff;
    color: #737881;
    display: block;
    width: 70px;
    height: 70px;
    -moz-background-clip: padding;
    -o-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    font-size: 21px;
    float: left;
    border: 5px solid #eaeaea;
    margin-left: -15px;
    margin-top: 50px;
    line-height: 60px;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-primary {
    background-color: $primary-color;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-secondary {
    background-color: #9e9e9e;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-success {
    background-color: $success-color;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-info {
    background-color: $info-color;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-warning {
    background-color: $warning-color;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-icon.bg-danger {
    background-color: $danger-color;
    color: #fff;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label {
    position: relative;
    background: #ffffff;
    padding: 15px;
    margin-left: 70px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-top: 45px;
    border: 0px solid #eaeaea;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid #eaeaea;
    padding: 20px;
    -webkit-box-shadow: 0 1px 6px rgba(150, 150, 150, 0.175);
    box-shadow: 0 1px 6px rgba(150, 150, 150, 0.175);
}/* 
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label:after {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 10px;
    margin-left: -11px;
    display: inline-block;
    border-top: 11px solid transparent;
    border-left: 0px solid #eaeaea;
    border-right: 11px solid #eaeaea;
    border-bottom: 11px solid transparent;

} */
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label h2, .timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label p {
    margin: 0;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label p + p {
    margin-top: 15px;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label h2 {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 30px;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label h2 a {
    color: $primary-color;
}
.timeline2-centered .timeline2-entry .timeline2-entry-inner .timeline2-label h2 span {
    -webkit-opacity: .6;
    -moz-opacity: .6;
    opacity: .6;
    -ms-filter: alpha(opacity=60);
    filter: alpha(opacity=60);
}
.timeline2-icon i {
    font-size: 28px;
}