
/*---------------------------------------------
      UI Calendar
----------------------------------------------*/

body {
    .rbc-event {
        background-color: $primary-color;
        border-radius: 0px;
        font-family: $sans-serif-family;
        padding: 5px 8px;
    }
    .rbc-event.rbc-selected{
        background-color: $accent-color;
    }
    .rbc-today {
        background-color: rgba(63, 81, 181, .1);
    }
    .rbc-calendar{
        margin-top: 10px;
    }
    .rbc-calendar .rbc-toolbar{
        .rbc-toolbar-label{
            font-family: $menu-family;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
        }
        .rbc-btn-group{
                background: #f5f5f5;
        }

        .rbc-btn-group button {
            cursor: pointer;
            border-radius: 0px;
            -o-border-radius: 0px;
            -ms-border-radius: 0px;
            -moz-border-radius: 0px;
            -webkit-border-radius: 0px;
            border: 0px solid transparent;
            font-family: $menu-family;
            font-weight: 300;
            text-shadow: none;
            font-size: 14px;
            line-height: 1.618;
        }
        .rbc-btn-group button.rbc-active, .rbc-btn-group button:hover, .rbc-btn-group button:focus, .rbc-btn-group button:active{
            color: #ffffff;
            background-color: $primary-color;
            outline: none;
            box-shadow: none;
        }
    }
    .rbc-month-view{
        margin-top: 15px;
    }

}
    body {
        .rbc-btn-group{
                background-color: transparent;
        }
        .rbc-btn-group button{
            padding: 5px 8px 5px 8px;
            text-transform: capitalize;
        }
    }

@media (max-width: 1100px) {
    body { 
        .rbc-toolbar{
            display: block;
        }
        .rbc-toolbar .rbc-toolbar-label{
            padding: 0px;
            display: block;
            text-align: left;
            margin: 0px 0;
            position: absolute;
            top: 0px;
        }
        .rbc-btn-group{
            margin-top: 40px;
            margin-right: 15px;
            background: #f5f5f5;
        }
        .rbc-btn-group:last-child{
            margin-top: 15px;
        }
    }

}

@media (max-width: 767px) {
    body .rbc-event{
        padding: 2px 5px;
        font-size: 12px;
        line-height: 19px;    
    }
}