.nav-toggle .navbar .navbar-wrapper{
    margin-left: 60px;
}
.navbar{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    height: 70px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -webkit-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -moz-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -o-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -ms-box-shadow: 0 2px 4px rgba(33,33,33,.2);

    a{
        vertical-align: middle;

        &:not(.btn):not(.dropdown-item){
            color: $white-color;
        }

        &.dropdown-item{
            color: $default-color;
        }
    }

    .navbar-brand{
        display: none;
    }

    &.bg-white{
      .input-group .form-control,
      .input-group.no-border .form-control{
        color: $default-color;

        @include placeholder(){
          color: $default-color;
        };
      }
      .input-group-addon i{
        color: $default-color;
        opacity: .5;
      }
    }

    .form-group,
    .input-group{
      margin: 0;
      margin-left: -3px;
      margin-right: 5px;
      .form-group-addon,
      .input-group-addon{
        color: $white-color;
      }
      &.no-border{
        .form-control{
          color: $white-color;

          @include placeholder(){
            color: $white-color;
          };
        }
      }
    }

    p{
        display: inline-block;
        margin: 0;
        line-height: 1.8em;
        font-size: 1em;
        font-weight: 400;
    }

    &.navbar-absolute{
        position: fixed;
        width: 100%;
        padding: 0px;
        z-index: 1033;
    }
    &.navbar-absolute .container-fluid{
        padding: 0px;
    }

    .documentation &{
        &.fixed-top{
            left: 0;
            width: initial;
        }
    }
    
    .navbar-right{
        height: 70px;
    }
    .navbar-wrapper{
        height: 70px;
        display: inline-flex;
        align-items: center;
        padding-left: 25px;
        margin-left: 260px;
      -webkit-overflow-scrolling: touch;
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

        .navbar-minimize{
            padding-right: 10px;

            .btn{
                margin: 0;
            }
        }

        .navbar-toggle{
            .navbar-toggler{
                padding-left: 0;
            }

            &:hover{
                & .navbar-toggler-bar.bar2{
                    width: 22px;
                }
            }
        }
    }

    .navbar-nav{
        &.navbar-logo{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 49px;
            top: -4px;
        }

        .nav-link.btn{
            padding: $padding-btn-vertical $padding-btn-horizontal;
            &.btn-lg{
                padding: $padding-large-vertical $padding-large-horizontal;
            }
            &.btn-sm{
                padding: $padding-small-vertical $padding-small-horizontal;
            }
        }

        .nav-link{
            text-transform: none;
            font-size: $font-size-base;
            padding: $padding-base-vertical $padding-base-horizontal;
            margin-right: 0px;
            &:after{
                margin-left: 10px;
            }
            img{
                width: 30px;
                height: 30px;
                margin-right: 7px;
            }
            i.i + p{
                margin-left: 6px;
            }

            i.i{
                font-size: 18px;
                position: relative;
                top: 3px;
                text-align: center;
                width: 21px;
            }

            &.profile-photo{
                .profile-photo-small{
                    width: 27px;
                    height: 27px;
                }
            }

            &.disabled{
                opacity: .5;
                color: $white-color;
            }
        }

        /* .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            background-color: $opacity-2;
            border-radius: $border-radius-small;
            color: $white-color;
        } */
    }
    .logo img{
        max-width: none;
        border-radius: 0px;
    }

    .logo-container{
        width: 27px;
        height: 27px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .navbar-brand{
        text-transform: uppercase;
        font-size: $font-size-small;
        padding-top: $padding-base-vertical;
        padding-bottom: $padding-base-vertical;
        line-height: $line-height-nav-link;
    }

    .navbar-toggler{
        width: 37px;
        height: 27px;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;
        font-size: 14px;

        & .navbar-toggler-bar.navbar-kebab{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin: 0 auto;
        }
    }

    .button-dropdown{
        .navbar-toggler-bar:nth-child(2){
            width: 17px;
        }
    }

    /* &.navbar-transparent{
        background-color: $transparent-bg !important;
        box-shadow: none;
        color: $white-color;
    } */

    /* &.navbar-primary{
        background-color: $primary-color !important;
        color: $white-color;
    
        box-shadow: 0 2px 4px rgba(33,33,33,.2);
        -webkit-box-shadow: 0 2px 4px rgba(33,33,33,.2);
        -moz-box-shadow: 0 2px 4px rgba(33,33,33,.2);
        -o-box-shadow: 0 2px 4px rgba(33,33,33,.2);
        -ms-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    
    } */


    &.bg-white:not(.navbar-transparent){
        a:not(.dropdown-item):not(.btn){
            color: $default-color;

            &.disabled{
                opacity: .5;
                color: $default-color;
            }
        }

        .button-bar{
            background: $default-color;
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            background-color: $opacity-gray-8;
            color: $default-color;
        }

        .logo-container{
            border: 1px solid $default-color;
        }
    }
}

.bg-default{
    background-color: $default-color !important;
}

.bg-primary{
    background-color: $primary-color !important;
}

.bg-red{
    background-color: $red-color !important;
}
.bg-info{
    background-color: $info-color !important;
}

.bg-success{
    background-color: $success-color !important;
}

.bg-danger{
    background-color: $danger-color !important;
}

.bg-warning{
    background-color: $warning-color !important;
}

.bg-white{
    background-color: $white-color !important;
}



/*Top bar Messages Dropdown*/

li.navbardd.nav-item {
    margin-top: 18px!important;
    margin-right: 10px;
}

.navbardd .dropdown-menu {
    min-width: 360px;
    padding-top: 0px;
    padding-bottom: 0px;
    .dropdown-item {
        outline: none;
        width: 100%;
        display: block;
        position: relative;
        padding: 15px 15px;
        border-bottom: 1px solid #eaeaea;
        margin-top: 0px;
        cursor: pointer;
    }
    .dropdown-item.all{
        background-color: $light-gray;
    }
    .dropdown-item.all a{
        color: $dark-gray;
    }
    .dropdown-item:last-child {
        border-bottom: none;
    }
    .user-img img {
        width: 30px;
        height: 30px;
        float: left;
        margin: 10px 15px 12px 0;
        opacity: 1;
    }
    .notify-icon i {
        width: 30px;
        height: 30px;
        float: left;
        margin: 6px 15px 2px 0;
        opacity: 0.8;
        border-radius: 50%;
        background-color: $medium-gray;
        line-height: 30px;
        text-align: center;
        color: $white-color;
        font-size: 14px;
        line-height: 30px;
    }

    .dropdown-item.unread .name {
        color: #717171;
        font-size: $font-size-base;
    }
    .dropdown-item .title, .dropdown-item .name{
        display: block;
        position: relative;
        padding-top: 5px;
        font-size: 14px;
        font-weight: normal;
        line-height: 23px;
    }
    .dropdown-item .desc {
        display: block;
        position: relative;
        padding-top: 0px;
        font-size: 13px;
        line-height: 21px;
        opacity: 0.8;
    }

    .dropdown-item.unread .name .time {
        color: #aaaaaa;
    }

    .dropdown-item .title .time {
        display: block;
        color: #aaaaaa;
    }

    .dropdown-item .profile-status {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        -o-border-radius: 50%;
        -ms-border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        display: inline-block;
        position: absolute;
        bottom: auto;
        right: 0px;
        top: 50%;
    }
    [data-status=available],.notify-icon[data-status=available] i{
        background-color: $success-color;
    }
    [data-status=busy],.notify-icon[data-status=busy] i{
        background-color: $danger-color;
    }
    [data-status=away],.notify-icon[data-status=away] i {
        background-color: $warning-color;
    }
    [data-status=offline],.notify-icon[data-status=offline] i{
        background-color: $dark-gray;
    }

    .dropdown-item.unread .desc {
        color: $dark-gray;
    }

}
.navbardd.dropdown {
    &.nav-item{
        list-style: none;
    }
}
.navbardd.dropdown, .chat-toggle{ 

    a.dropdown-toggle:after{
        display: none;
    }
    .navbar-toggler{
        line-height: 38px;
        height: 46px;
        margin-right: 20px;
        i{
            font-size: 18px;
        }
        .badge{
            right: -4px;
        }
    }
    a.dropdown-toggle, .navbar-toggler{
        position: relative;
    }
    a.dropdown-toggle .badge, .navbar-toggler .badge{
        position: absolute;
        font-size: 10px;
        padding: 4px;
        margin: 0px 0 0 -5px;
        width: 18px;
        height: 18px;
        top: -2px;
        line-height: 11px;
        border-radius: 2px;
    }

    a.dropdown-toggle .badge-accent, .navbar-toggler .badge-accent {
        background-color: $accent-color;
        color: #ffffff;
    }

}
.topbar-search.input-group{
    cursor: pointer;
    margin: 0 15px ;
    input{
        display: none;
        border:none;
    }
    &.open{
        height: 40px;
        margin: 0 15px 0 15px;
        width: 180px;
        border-bottom: 1px solid $opacity-8;
        i{
            margin-top: 11px;
        }
        input{
                color: #ffffff;
                display: block;
            }
    }
    input.form-control:focus, input.form-control, .input-group-addon{
        background-color: transparent;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    .input-group-addon{
        border:none;
        padding-left: 0px;
        padding-right: 5px;
    }
    input.form-control{
        height: 40px;
        min-height: 40px;
    }
}
[data-topbartype="light"]{
    .topbar-search.input-group{
        &.open{
            border-bottom: 1px solid $black-opacity-4;
            input{
                color: $secondary-color;
            }
        }
    }
}
.navbar .container-fluid{
    flex-wrap: nowrap;
    height: 70px;
    display: inline-block;
    .logo{
        width: auto;
        display: inline-block;
        float: left;
    }
    .collapse.navbar-collapse{
        display: inline-block;
        float: right;
        width: auto;
        padding-right: 10px;
        .navbar-nav{
            display: inline-block;
            float: left;
            width: auto;
            .userdd, .nav-item{
                float: left;
                display: inline-block;
            }
            .nav-item{
                margin-top: 12px;
            }
        }
    }
}
@media screen and (max-width: 991px){
    .navbar .navbar-wrapper{
        margin-left: 0px;
    }
    .nav-toggle .navbar .navbar-wrapper{
        margin-left: 260px;
    }
}

@media screen and (max-width: 850px){
    .userdd a span{
        display: none;
    }
    .navbar .navbar-nav .userdd a.nav-link:after{
        margin-left: 0px;
    }
}
@media screen and (max-width: 991px){
    .navbar form.topbar-search-form{
        display: none;
    }
}
@media screen and (max-width: 768px){
    .navbardd .dropdown-menu {
        min-width: 300px;     
    }
}
.navbar-nav .dropdown-menu {
    position: absolute;
}


/*Transparent topbar style*/
[data-topbar="transparent"]{
  .navbar{
    height: 70px;

    &.navbar-absolute{
        position: absolute;
    }

    .navbar-wrapper, .container-fluid, .navbar-right{
        height: 70px;
    }
  }
}

.navbar-toggle i{
    font-size: 17px;
}
.navbardd i{
    font-size: 18px;
    top: 4px;
    position: relative;
}
.topbar-search-form i{
    font-size: 18px;
}
.chat-toggle i{
    font-size: 21px;
    position: relative;
    top: 1px;
    right: 4px;
}
@media screen and (max-width: 575px){
    .navbar-wrapper .navbardd{
        display: none;
    }
    .navbar .navbar-nav .nav-link{
        padding: 0.5rem 2px;
    }
    .chat-toggle .navbar-toggler{
        margin-right: 15px;
    }
    .navbar .container-fluid{
        min-width: 300px;
    }
    .navbar .navbar-nav .userdd img{
        margin: 0px;
    }
    .navbar .navbar-nav .userdd a:after{
        display: none;
    }
    .chat-toggle .badge{
        display: none;
    }
    .chat-toggle .navbar-toggler{
        margin-right: 10px;
    }
}
@media screen and (max-width: 420px){
    /* .navbar .navbar-nav .userdd a, .navbar .navbar-nav .userdd div{
        display: none;
    } */
    .nav-toggle-chat .navbar .navbar-wrapper{
        margin-left: -50px;
    }
}
@media screen and (max-width: 400px){
    .navbar .navbar-nav .userdd a, .navbar .navbar-nav .userdd div{
        display: none;
    }
    .nav-toggle-chat .navbar .navbar-wrapper{
        margin-left: -50px;
    }
}
@media screen and (max-width: 321px){
    .navbar .navbar-nav .userdd a, .navbar .navbar-nav .userdd div{
        display: none;
    }
    .nav-toggle-chat .navbar .navbar-wrapper{
        margin-left: -50px;
    }
}
.navbar .navbar-nav .nav-link{
        padding: 3px 7px;
}
.navbar-toggle.chat-toggle{
    top: -3px;
    position: relative;
}
@media screen and (max-width: 991px){
    .navbar .navbar-nav .nav-link{
            padding: 8px 7px;
    }
    .navbar-toggle.chat-toggle{
        top: 0px;
        position: relative;
    }
}