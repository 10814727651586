.timesheetsHeader {
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.08);
}

.striped {
    background-color: rgba(0, 0, 0, 0.05);
}

.lastRow {
    border-bottom-style: groove;
    border-bottom-color: #f5f5f5;
}

.lastRow>td {
    margin-bottom: 30px;
}

.areaRow {
    font-size: 16px;
    font-weight: 400;
}

.borderOff {
    border: none !important;
    background-color: #ffffff;
}

.colCenter {
    text-align: center;
}

.innerTable {
    margin-bottom: 0!important;
}

.areaSurmmaryName {
    width: 60%;
}

@page {
    size: A4;
}

@page :left {
    margin: 0.8cm;
}

@page :right {
    margin: 0.8cm;
}

@media print {
    html, body, p, h1, h2, div, .wrapper, .main-panel, .content {
        background-color: #ffffff!important;
    }
    .printHeader {
        background-color: #ffffff!important;
        border: none!important;
    }

    .parentRowPrint {
        background-color: #ffffff!important;
        border: none!important;
        margin: 0;
    }
    .areaSurmmaryName {
        width: 69%;
    }
}



