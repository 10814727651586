.table {
  color: inherit;

  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    >thead>tr>th,
    >tbody>tr>th,
    >tfoot>tr>th,
    >thead>tr>td,
    >tbody>tr>td,
    >tfoot>tr>td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  >thead>tr>th {
    border-bottom-width: 1px;
    font-size: 1.45em;
    font-weight: $font-weight-light;
    border: 0;
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {

      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    padding: 12px 7px;
    vertical-align: middle;
  }

  .th-description {
    max-width: 150px;
  }

  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }

  .td-total {
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  >tbody>tr {
    position: relative;
  }
}

.table-shopping {
  >thead>tr>th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }

  >tbody>tr>td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }

  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;

    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }

  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }

  .td-name {
    min-width: 200px;
  }

  .td-number {
    text-align: right;
    min-width: 145px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

#tables .table-responsive {
  margin-bottom: 30px;
}

/*---------------------------------------------
    Tables
----------------------------------------------*/

.table>thead>tr>td.primary,
.table>tbody>tr>td.primary,
.table>tfoot>tr>td.primary,
.table>thead>tr>th.primary,
.table>tbody>tr>th.primary,
.table>tfoot>tr>th.primary,
.table>thead>tr.primary>td,
.table>tbody>tr.primary>td,
.table>tfoot>tr.primary>td,
.table>thead>tr.primary>th,
.table>tbody>tr.primary>th,
.table>tfoot>tr.primary>th {
  background-color: rgba(31, 181, 172, 0.2);
}

.table>thead>tr>td.accent,
.table>tbody>tr>td.accent,
.table>tfoot>tr>td.accent,
.table>thead>tr>th.accent,
.table>tbody>tr>th.accent,
.table>tfoot>tr>th.accent,
.table>thead>tr.accent>td,
.table>tbody>tr.accent>td,
.table>tfoot>tr.accent>td,
.table>thead>tr.accent>th,
.table>tbody>tr.accent>th,
.table>tfoot>tr.accent>th {
  background-color: rgba(250, 133, 100, .2);
}

.table>thead>tr>td.purple,
.table>tbody>tr>td.purple,
.table>tfoot>tr>td.purple,
.table>thead>tr>th.purple,
.table>tbody>tr>th.purple,
.table>tfoot>tr>th.purple,
.table>thead>tr.purple>td,
.table>tbody>tr.purple>td,
.table>tfoot>tr.purple>td,
.table>thead>tr.purple>th,
.table>tbody>tr.purple>th,
.table>tfoot>tr.purple>th {
  background-color: rgba(153, 114, 181, .2);
}

.table>thead>tr>td.secondary,
.table>tbody>tr>td.secondary,
.table>tfoot>tr>td.secondary,
.table>thead>tr>th.secondary,
.table>tbody>tr>th.secondary,
.table>tfoot>tr>th.secondary,
.table>thead>tr.secondary>td,
.table>tbody>tr.secondary>td,
.table>tfoot>tr.secondary>td,
.table>thead>tr.secondary>th,
.table>tbody>tr.secondary>th,
.table>tfoot>tr.secondary>th {
  background-color: #9e9e9e;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  border-color: #cacaca;
  padding: 10px;
}

body .table th,
body .table td {
  padding: 15px 8px 5px 8px;
  vertical-align: top;
  border-top: 1px solid #f5f5f5;
}

.table>thead>tr>th {
  border-bottom-width: 1px;
  font-size: 16px;
  font-weight: 400;
  border: 0;
}

/*React data grid*/

body .react-grid-Cell {
  padding-left: 15px;
}

body .react-grid-HeaderCell {
  padding: 0 15px;
}

body .widget-HeaderCell__value {
  line-height: 50px;
}

body .rdg-selected {
  border: 2px solid $primary-color;
}

body .rdg-selected .drag-handle {
  background-color: $primary-color;
}

/*React datatable*/
.dt-disp {
  .col-xs-12 {
    width: 100%;
  }
}

.dt-disp .input-group-btn button {
  height: 52px;
}

.dt-disp #formGroupPagination {
  margin-left: 10px;
}

.dt-disp .btn-group-page-nav .btn.active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.dt-disp {
  padding: 0 30px;

  .col-md-4.col-xs-12 {
    width: auto;
    display: inline-block;
    float: right;
    flex: none;
    padding: 0px;
  }

  .col-md-4.col-xs-12.text-right {
    float: right;
    width: 100%;
    display: block;
    max-width: none;
    flex: none;
    margin: 0 0 15px 0;
    padding: 0px;

    .btn-group {
      float: left;

      button {
        height: 36px;
        line-height: 8px;
        width: 46px;
        padding: 6px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 767px) {
  body .dt-disp .col-md-4.col-xs-12 {
    margin-right: 15px;
    width: 100%;
    margin-bottom: 15px;

    .form-group {
      margin-bottom: 0px;
      width: 100%;
      white-space: nowrap;

      select {
        max-width: 100px;
        display: inline;
        margin-right: 5px;
      }
    }
  }

  body .dt-disp .col-md-4.col-xs-12.text-right .btn-group button {
    padding: 4px 3px;
    height: 36px;
    width: 36px;
  }
}

table.fixheight {
  margin-bottom: 0px;

  td {
    white-space: nowrap;
  }
}