
/*----------------------------------------------
      Mailbox
-----------------------------------------------*/

.mail_content {
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    margin-bottom: 30px;
}
.mail_tabs {
    margin: 0px;
    border: 0px solid #e8e8e8;
    border-right: 0px;
    padding: 0px 0;
    background-color: transparent;
    min-width: 125px;
}
.mail_tabs li {
    display: block;
}
.mail_tabs li a {
    display: block;
    color: #555555;
    padding: 10px 15px 10px 15px;
    text-decoration: none;
    background-color: transparent;
    margin-bottom: 0px;
    border: 1px solid transparent;
    border-right: none;
}
.mail_tabs li a i {
    font-size: 14px;
    margin-right: 10px;
}
.mail_tabs li.compose a{
    color: $primary-color;
}
.mail_tabs li a.active, .mail_tabs li a:hover {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-right: none;
}
.mail_head {
    display: inline-block;
    width: auto;
    float: left;
    margin: 0 15px 0 0;
    font-family: $menu-family;
    font-weight: 300;

    font-size: 19px;
    line-height: 30px;
    color: #676767;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
}
.mail_head_icon {
    margin: 13px 0px 0 15px;
    cursor: hand;
    cursor: pointer;
}
.mail_head_icon.pull-right {
    margin: 7px 0px 0 15px;
}
.mail_more_btn, .mail_nav, .mail_count_nav {
    margin: 30px 0;
    display: none;
}
.mail_count_nav {
    margin: 0px 15px 15px 0;
    line-height: 19px;
    clear: both;
    width: 100%;
    display: none;
    text-align: right;
    float: none;
}
.mail_view_title {
    margin: 5px 0 0px 0;
}
.mail_view_title h3 {
    margin: 0px;
}
.mail_view_info {} 
.mail_view {
    margin: 20px 0 0 0;
}
.mail_view_attach h4 i{
    font-size: 18px;
}
.mail_view_attach li .file {
    display: block;
    width: 200px;
    max-width: 200px;
    height: auto;
    max-height: auto;
    cursor: hand;
    cursor: pointer;
    margin-bottom: 10px;
}
.mail_view_attach li.list-inline-item:not(:last-child){
    margin-right: 30px;
}
.mail_view_attach li .file img {
    width: 200px;
    max-width: 200px;
    height: auto;
    max-height: auto;
}
.mail_view_attach li .title {
    position: relative;
    display: block;
    margin-bottom: 5px;
    color: #777777;
    text-decoration: none;
    padding: 0 5px;
}
.mail_view_attach li .title span {
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 90%;
    color: #aaaaaa;
}
.mail_view_attach li .actions {
    position: relative;
    display: block;
    padding: 0 5px;
    margin: 0px 15px 20px 0px;
    line-height: 20px;
}
.mail_view_attach li .actions a {
    color: #777777;
    text-decoration: none;
    font-size: 85%;
    color: #999999;
    padding: 0px;
    margin-right: 5px;
}
.mail_view_reply {
    margin: 0px 0 0 0;
}
.mail_cc_bcc{
    margin-top: 30px;
}
.mail_cc_bcc .labels {
    float: right;
}
.mail_cc_bcc .labels .label {
    cursor: hand;
    cursor: pointer;
}
.mail_compose_cc, .mail_compose_bcc {
    display: none;
}
.bootstrap-tagsinput {
    width: 100%;
}
.bootstrap-tagsinput input {
    width: 100% !important;
}
.composebtn{
    margin-top: 0px;
}

.mail_more_btn button.btn.btn-secondary{
    background: #eaeaea;
    color: #333333;
}
.mail_list {
    /* table > thead > tr> th,
    table > tbody > tr> td{
        padding-left: 3px;
    } */
    padding: 0px 0px 0px 3px;
    .msgtext a{
        color: #555555;
        font-weight: normal;
        display: block;
        margin: 3px 0 0px 0;
    }
    .msg_time a{
        color: #aaaaaa;
    }
    .msgby{
        color: #999999;
        font-weight: normal;
        display: block;
        font-size: 85%;
    }
    .user-img{
       width: 8%;
        min-width: 80px;
    }
    .user-img img{
        left: 10px;
        position: relative;
        top: 0px;
        width: 44px;
        height: 44px;
        margin: 6px 0 6px 0;
    }
    .open-view{
        width: 60%;
        min-width: 300px;
    }
    .check{
        padding-left: 3px;
        width: 5%;    
        input{
            top: 0px;
        }
    } 
    .tag{
        width: 12%;
        min-width: 100px;
        .badge{
            min-width: 60px;
            margin: 0px 0 0 0;
            float: left;
        }
    }
    .star{
        margin-top: 0px;
    }
    .timestamp{
        width: 12%;
        min-width: 75px;
        white-space: nowrap;
        padding-right: 2px;
        .msg_time{
            margin: 0px 0 0 0;
            font-size: 90%;
            display: block;
        }
    }
}
.mail_tabs_wrap{
    padding-right: 0px;
}
@media (max-width: 768px) {
  .mail_tabs_wrap{
    padding-right: 15px;
    padding-left: 0px;
  }  
}
.mail_content .mail_list {
    padding: 15px 15px 0 15px;
}

.mail-view-btns{
    float: right;
    button{
        margin: 0 0 0px 10px;
        height: 36px;
        line-height: 23px;
        padding: 9px 12px;  
    }
    .dropdown button{
        margin: 0px;
    }
}

@media (max-width: 576px) {
    .mail-view-btns{
        button{
            display: block;
        }
        .dropdown button{
            display: none;
        }
    }
}
@media (min-width: 575px) {
    .mail-view-btns{
        button{
        }
        .dropdown {
            display: none;
        }
    }
}
.mail-compose-btns{
    button{
        margin-bottom: 15px;
    }    
}
.mail_nav button{
    margin: 0 0 0 15px;
    i{
        font-size: 18px;
        top: 4px;
        position: relative;
        opacity: 0.7;
    }
}
body .mail_list .table tr:first-child th, body .mail_list .table tr:first-child td{
    border-top: none;     
}

@media (max-width: 1200px) {
    body .mail_list .imp{
            display: none;
    }
}
@media (max-width: 767px) {
    body .mail_list .check{
            display: none;
    }
    body .mail_list .user-img{
        padding-left: 0px;
        min-width: 60px;
        img{
            left: 0px;
        }
    }
    .mail_tabs_wrap{
        margin-top: -15px;
    }
    .mail_tabs li a.active, .mail_tabs li a:hover {
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
    }
    .mail-view-btns button{
        display: inline-block;
    }
    .mail-view-btns .dropdown{
        display: none;
    }


}

@media (max-width: 575px) {
    .mail-view-btns button{
        display: none;
    }
    .mail-view-btns .dropdown{
        display: none;
    }
    .mail_view_info .float-right{
        float: left !important;
        margin-top: 10px;
    }

}