
/*------------------------------------------
    Dashboard
------------------------------------------*/

.db_box {
    padding: 15px;
    padding: 15px 15px 15px 25px;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    vertical-align: top;
}
 .db_box.db_box_large{
    max-width: inherit;
    background-color: #ffffff;
    float: left;
    margin: 0px 2% 0px 0px;
    padding: 30px;
    color: #757575 !important;
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
    max-width: 100%;
 }

 .db_box.db_box_large .bold
 {
    text-align: left;
    float: left;
 }

 .db_box.db_box_large .pull-right
 {
    text-align: right;
 }

 .db_box.db_box_large .clearfix{
    width: 100%;
    height: 1px;
    margin-bottom: 40px;
 }


.r1_graph1, .r1_graph2, .r1_graph3 {
    min-height: 80px;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    max-width:230px;
    float: left;
    margin-right: 15px;
}
.r1_graph4, .r1_graph5 {
    min-height: 135px;
    width: 100%;
    margin-bottom: 30px;
    max-width:230px;
    float: left;
    margin-right: 15px;
}
.r1_maingraph {
    min-height: 340px;
    width: 100%;
    background: #ffffff;
    margin-bottom: 30px;
}
.r1_maingraph .switch {
    cursor: hand;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 99;
}
.r1_maingraph .switch .icon-secondary {
    opacity: 0.8;
}
.r1_graph1 {
    background: $primary-color;
    color: #ffffff;
}
.r1_graph2 {
    background: $purple-color;
    color: #ffffff;
}
.r1_graph3 {
    background: $warning-color;
    color: #ffffff;
}
.r1_graph4 {
    background: $accent-color;
    color: #ffffff;
}
.r1_graph4 {
    text-align: center;
}
.r1_graph4 span {
    text-align: left;
    display: block;
}
.r1_graph4 #gauge-meter {
    margin-top: -8px;
}
.r1_graph4 #gauge-meter-text {
    position: absolute;
    top: 105px;
    width: 100%;
    text-align: center;
    margin-left: -30px;
    font-size: 15px;
    color: #ffffff;
    line-height: 20px;
    font-weight: 700;
}
.r1_graph4 #gauge-meter-text:after {
    content: "MB";
    font-size: 13px;
    font-weight: 400;
}
.r1_graph5 {
    background: $warning-color;
    color: #ffffff;
}
.r1_graph5 .icon-1, .r1_graph5 .icon-2 {
    font-size: 10px;
}
.r1_graph5 .icon-2 {
    color: #eaeaea;
}
.r2_graph1 {
    min-height: 200px;
    width: 100%;
    background: #ffffff;
    margin-bottom: 15px;
    overflow: hidden;
}
.r2_graph1 .rickshaw_legend .line {
    float: left;
    width: auto;
    margin-right: 3px;
    clear: none;
    padding: 2px 6px;
    display: inline-block;
}
.r2_graph1 .rickshaw_legend .action {
    font-size: 10px;
    margin-right: 2px;
}
.r2_graph1 .rickshaw_legend .line .swatch {
    display: inline-block;
    margin-right: 2px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    height: 10px;
    width: 10px;
}
.r2_graph1 .rickshaw_legend .label {
    display: inline;
    font-size: 12px;
    background-color: transparent;
    color: #777777;
    font-weight: 400;
    line-height: 19px;
}
.r2_graph1 #offset_form, .r2_graph1 #interpolation_form {
    display: none;
}
.r2_graph1 .rickshaw_sliders {
    display: inline-block !important;
}
.r2_graph1 .rickshaw_sliders section {
    width: 47%;
    margin-right: 1%;
    float: left;
    display: inline-block;
    padding-right: 10px;
}
.r2_graph1 #legend ul {
    display: inline-block;
    margin: 0px;
}
.r2_graph1 #renderer_form.toggler {
    margin: 0 0 10px 0;
    text-align: right;
}
.r2_counter1, .r2_counter2 {
    min-height: 70px;
    width: 100%;
    background: #ffffff;
    margin-bottom: 30px;
}
.r2_counter1 {
    background: $primary-color;
    color: #ffffff;
}
.r2_counter2 {
    background: $purple-color;
    color: #ffffff;
}
.r3_notification {
    background: #ffffff;
    margin-bottom: 15px;
    min-height: 400px;
}
.r3_weather {
    margin-bottom: 15px;
    min-height: 390px;
    color: #ffffff;
}
.r3_todo {
    background: #ffffff;
    margin-bottom: 15px;
    min-height: 400px;
}
.r4_counter {
    min-height: 100px;
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(33,33,33,.2);
    -webkit-box-shadow: 0 1px 3px rgba(33,33,33,.2);
    -moz-box-shadow: 0 1px 3px rgba(33,33,33,.2);
    -o-box-shadow: 0 1px 3px rgba(33,33,33,.2);
    -ms-box-shadow: 0 1px 3px rgba(33,33,33,.2);
}
.r4_counter .stats {
    padding-left: 85px;
}
.r4_counter .i {
    margin-right: 0px;
    width: 66px;
    height: 66px;
    text-align: center;
}
.r4_counter h4 {
    margin: 10px 0 5px 0;
}









/*small graph widgets*/
.graph_widget{
    min-width: 230px;
    .chart-area{
        max-width: 90px;
    }
    .widdata{
        max-width: 110px;
        display: inline-block;
    }
    .widtitle{
        margin: 0px 15px 0px 15px;
        font-size: 20px;
        line-height: 37px;
    }
    .widtag{
        margin: 0px 15px 0px 15px;
        font-size: 13px;
        opacity: 0.8;
        white-space: nowrap;
    }
    .widdata-pie{
        .widtitle, .widtag{
            margin-left: 0px;
        }
    }
}

@media (min-width: 991px) {
.graph_widget{
    .widdata{
        max-width: 95px;
    }
    .widtag{
        margin: 0px 15px 0px 15px;
        font-size: 12px;
    }
}
}
@media (min-width: 767px) {
.graph_widget{
    .widdata{
        max-width: 95px;
    }
    .widtag{
        margin: 0px 15px 0px 15px;
        font-size: 12px;
    }

}    
}


.graph_widget.pie{
    padding-left: 0px;
}

.biggraph_widget{
    margin: 0px 30px 30px 0px;
    padding: 30px;
    &.inline{
        padding: 15px;
    }
    min-width: auto;
    .chart-wrap{
        width: 100%;
    }
    .chart-area{
        min-width: none;
    }
    .widsubtag{
        margin: 0px 15px 15px 0px;
        font-size: 13px;
        opacity: 0.8;
    }
    .widdata{
        min-width: 100%;
        display: inline-block;
        margin: 15px 15px 0px 0px;
    }
    .widtitle{
        font-size: 20px;
        margin: 0px 15px 0px 0px;
    }
    .widtag{
        margin: 0px 15px 0px 0px;
        font-size: 13px;
        opacity: 0.8;
        white-space: nowrap;
    }
    
    &.centered{
        .chart-area{
            height: 90px;
            width: 120px;
            position: relative;
            left: 50%;
            min-width: auto;
        }
        text-align: center;
        .chart{
            width: 100%;
        }
        .widtag, .widtitle, .widsubtag{
            margin-right: 0px;
        }
    }
    &.transparent{
        min-width: auto;
        margin-bottom: 0px;
        display: inline-block;
        .widdata{
            margin-top: 0px;
        }
    }

}
/* 
@media (min-width: 991px) {
    .biggraph_widget{
        padding: 25px;
    }
}
@media (min-width: 767px) {
    .biggraph_widget{
        padding: 25px;
    }
} */


.iconbox{
    margin: 0px 30px 30px 0px;
    padding: 15px;
    text-align: center;
    &.inline{
        margin-bottom: 0px;
        .widtitle{
            font-size: 20px;
            white-space: nowrap;
        }
    }
    .widtitle{
        font-size: 20px;
    }
    .widicon{
        margin: 15px 0;
        font-size: 35px;
        display: inline-block;
    }
    .widtag, .widtitle, .widsubtag{
        margin: 0px;
        white-space: nowrap;
    }
    .widdata{
        width: 100%;
    }
    &.colored{
        .widtag, .widtitle, .widsubtag{
            color: #ffffff;
        }
        .widtag, .widsubtag{
            opacity: 0.7;
        }
    }
}
.icon_stats{
    overflow: hidden;
    .widtitle{
        margin: 0px;
        font-size: 22px;
        line-height: 35px;
    }
    .widtag{
            opacity: 0.7;
            margin: 0px 15px 0px 0px;
            font-size: 13px;
            white-space: nowrap;
    }
    .stats{
        margin-top: 5px;
        margin-left: 20px;
        width: calc(100% - 72px);
    }
    .widicon{
        margin-left: 10px;
        font-size: 35px;
        line-height: 66px;
        max-width: 42px;
    }
}



.bar_graph_rows{
    margin: 0px 0 0 0;
    width: 100%;
    padding: 0 15px;
    
    .graph-stats{
        border-bottom: 1px solid #eeeeee;
        width: 100%;
        display: inline-block;
        padding: 15px 0 15px 0;
    }
    .graph-stats:last-child{
        border-bottom: none;
        padding: 15px 0 0px 0;
    }
    .widtitle, .widtag{
        margin-left: 0px;
    }
}
.progress_bar_widget{
    h5{
        margin-top: 15px;
    }
    .progress{
        margin: 12px 0 0 0;
        height: 5px;
    }
}
.user-inline-img{
    white-space: nowrap;
}
.user-inline-img img{
    margin: 5px 15px 5px 0;
    height: 40px;
}
@media (max-width: 767px) {
    .dash-4-linechart{
        padding-left: 0px !important;
        margin-top: 15px;
        margin-left: -15px;
    }
}