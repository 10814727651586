
/*---------------------------------------------
    Popovers     
----------------------------------------------*/

.popover {
    font-family: $sans-serif-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.25);
    -o-box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.25);
    -ms-box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.25);
    -moz-box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.25);
    -webkit-box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.25);
}
.popover-header {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: $primary-color;
    border-bottom: 0px solid #ebebeb;
    border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    color: #ffffff;
}
.popover-body {
    padding: 9px 14px;
}
.popover.top>.arrow {
    border-top-color: #e8e8e8;
}
.popover.left>.arrow {
    border-left-color: #e8e8e8;
}
.popover.right>.arrow {
    border-right-color: #e8e8e8;
}
.popover.bottom>.arrow {
    border-bottom-color: #e8e8e8;
}
.popover.success .popover-header {
    background: $success-color;
}
.popover.warning .popover-header {
    background: $warning-color;
}
.popover.danger .popover-header {
    background: $danger-color;
}
.popover.info .popover-header {
    background: $info-color;
}
.popover.accent .popover-header {
    background: $accent-color;
}
.popover.purple .popover-header {
    background: $purple-color;
}
.popover.primary .popover-header {
    background: $primary-color;
}
.popover.secondary .popover-header {
    background: #9e9e9e;
}
[rel="popover"] {
    cursor: hand;
    cursor: pointer;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before{
    border-top-color: rgba(0, 0, 0, 0.1);
}
.popover-inner{
    padding: 15px;
}
.popover-body{
    padding: 8px 0 0px 0;
}
.popover-header{
    padding: 0px;
    background-color: transparent;
    color: $primary-color;
    line-height: 23px;
}
.popover-btns{
    button{
        margin: 0 15px 15px 0;
    } 
}