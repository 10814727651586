
/*---------------------------------------------
    DropZone File Uploader (Drag n Drop)
----------------------------------------------*/
 .dropzone .droparea{
    min-height: 200px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    padding: 20px 20px;
    box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -webkit-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -moz-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -o-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    -ms-box-shadow: 0 2px 4px rgba(33,33,33,.2);
    height: 100%;
    width: 100%;
    display: block;
    background: #f5f5f5;
    min-height: 200px;
}
.dropzone .droparea[aria-disabled="true"]{
    background: #ccc;
    opacity: 0.5;
}