
/*-----------------------------------------------------------
    Jumbotron
----------------------------------------------------------*/

.jumbotron{
    padding: 25px;
    margin-bottom: 2rem;
    background-color: #f5f5f5;
    border-radius: 0px;
}
.lead{
    font-size: 18px;
    font-weight: 300;
    line-height: 29px;	
}