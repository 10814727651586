
/*---------------------------------
    Typography
-----------------------------------*/

h1, h2, h3, h4, h5, h6 {
    /*  font-family: $menu-family;*/
    
    margin: 10px 0;
    font-family: $sans-serif-family;
    font-weight: 400;
    color: #505458;
}
h1{
    font-size: 32px;
    line-height: 52px;
}
h2{
    font-size: 28px;
    line-height: 45px;
}
h3{
    font-size: 24px;
    line-height: 39px;
}
h4{
    font-size: 20px;
    line-height: 32px;
}
h5{
    font-size: 16px;
    line-height: 26px;
}
h6{
    font-size: 13px;
    line-height: 21px;
}
.light {
    font-weight: 100;
}
.bold {
    font-weight: 700;
}
.semi-bold {
    font-weight: 400;
}
blockquote, .blockquote-reverse, blockquote.pull-right {
    font-size: 16px;
    border-color: $primary-color;
}
blockquote.pull-right {
    width: 100%;
}
blockquote.purple {
    border-color: $purple-color;
}
blockquote.accent {
    border-color: $accent-color;
}
blockquote.background {
    background: #f5f5f5;
}
.well {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background: #f5f5f5;
    border-color: #eaeaea;
}
.well.primary, .well.primary * {
    background-color: $primary-color;
    color: #ffffff;
    border-color: $primary-color;
}
.well.transparent {
    background-color: transparent;
}
a {
    color: $primary-color;
}
a:hover, a:focus {
    color: #333333;
}

.text-dark {
    color: #333333;
}
.text-muted {
    color: #999999;
}
.text-primary {
    color: $primary-color !important;
}
.text-info {
    color: $info-color;
}
.text-warning {
    color: $warning-color;
}
.text-danger {
    color: $danger-color;
}
.text-success {
    color: $success-color;
}
.text-purple {
    color: $purple-color;
}
.text-accent {
    color: $accent-color;
}
.text-light {
    color: #f5f5f5;
}
.bg-muted {
    background: #eaeaea;
    padding: 3px 8px;
}
.bg-primary {
    background: $primary-color !important;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-dark{
    background: $black-menubg !important;
}
.bg-red {
    background: $red-color !important;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-info {
    background: $info-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-warning {
    background: $warning-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-danger {
    background: $danger-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-success {
    background: $success-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-purple {
    background: $purple-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-accent {
    background: $accent-color;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-secondary {
    background: #9e9e9e;
    padding: 3px 8px;
    color: #ffffff;
}
.bg-white {
    background: #ffffff;
    padding: 3px 8px;
    color: #777777;
}
ul, ol{
    padding-left: 15px;
}